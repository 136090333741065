import { useStaticQuery, graphql } from "gatsby"

export const useNOEQuery = ()=> {

    return useStaticQuery(graphql`
    
        query NoeQuery {

            wpPage(title: {eq: "NETWORKS OF EXCELLENCE"}) {
                ACF_NOE {
                    noeHero {
                        animatedTitle
                        desktopImage {
                            localFile {
                                publicURL
                            }
                        }
                        mobileImage {
                            localFile {
                                publicURL
                            }
                        }
                    }

                    info{
                        copy
                        ctaLabel
                        ctaUrl
                    }

                    map {
                        title
                    }

                }
            }
            
        } 
    `)
}