import React from "react"
import { Wrapper, BlockContent, BlockContentMobile} from "./TopHero.styled"
import TypeWriteLine from "../../TypeWriteLine/TypeWriteLine"
import BottomLine from "../../BottomLine/BottomLine"
import 'twin.macro'

//Assets
import chooseLogo from "../../../images/choose-logo.svg"


const TopHero = ({assets})=> {   
    console.log(assets.desktopImage.localFile.publicURL); 
    return(
        <Wrapper>

            <BlockContent bgasset={assets.desktopImage.localFile.publicURL}>
                <div className="text-wrapper">
                    <img className="chooseLogo" src={chooseLogo} alt="Choose"/>
                    <TypeWriteLine copy={assets.animatedTitle}/>
                </div>
                <BottomLine/>
            </BlockContent>

            <BlockContentMobile bgassetmob={assets.mobileImage.localFile.publicURL}>
                <div className="text-wrapper">
                    <img className="chooseLogo" src={chooseLogo} alt="Choose"/>
                    <TypeWriteLine copy={assets.animatedTitle}/>
                </div>
                <BottomLine/>
            </BlockContentMobile>

        </Wrapper>
    )
}

export default TopHero