import React from "react"
import { Link } from "gatsby"
import {Wrapper, TextBlock} from "./InfoBlock.styled"
import BottomLine from "../../BottomLine/BottomLine"
import WordCarousel from "../WordCarousel/WordCarousel";

const infoBlock = ({info, bgColor})=> { 
    return(
        <Wrapper bgColorClass={bgColor}>
            <TextBlock dangerouslySetInnerHTML={{ __html: info.copy }}/>
            <WordCarousel />
            <div className="cta-container"><Link className={`cta white`} to={info.ctaUrl}>{info.ctaLabel}</Link></div>
            <BottomLine/>
        </Wrapper>
    )
}

export default infoBlock;