import React, {useEffect} from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

// Components
import TopHero from "../components/NOE/TopHero/TopHero";
import InfoBlock from "../components/NOE/InfoBlock/InfoBlock";
import Map from "../components/NOE/Map/Map";

// Hooks
import { useNOEQuery } from "../hooks/useNOEQueries";

const ExcellencePage = ({ children }) => {
  const data = useNOEQuery();
  
  useEffect(() => {
    // Mount

    // Unmount
    return () => {

    } 
  }, []); 

  return(
    <Layout>
      <Seo title="Networks of Excellence" />
      <TopHero assets={data.wpPage.ACF_NOE.noeHero} />
      <InfoBlock info={data.wpPage.ACF_NOE.info} bgColor='#EBEAEA'/>
      <Map data={data.wpPage.ACF_NOE.map} />
    </Layout>
  )
}
export default ExcellencePage;