import React, { useEffect, useRef } from "react"
import { Wrapper } from "./WordCarousel.styled"
import 'twin.macro'
import gsap from "gsap"

import communityLogo from "../../../images/community-logo.png"


const WordCarousel = ({assets})=> {  
    
    const words = useRef();


    useEffect(() => {

        const wordsWrapheight = words.current.offsetHeight;
        const $ = gsap.utils.selector(words);

        console.log(wordsWrapheight);

        let tl = new gsap.timeline({
            scrollTrigger: {
              trigger: words.current,
              toggleActions: 'play none none pause',
              start: '50% bottom'
            },
            repeat: -1
        })

        tl.set($('p:nth-of-type(3)'), {color:'#C53427'})
    
        tl.to(words.current, {y:`-=10%`, duration: 0.5, ease:"power2.inOut"}, "+=1")
        tl.to($('p'), {opacity:'0', duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        tl.to($('p:nth-of-type(4)'), {color:'#C53427', opacity:1, duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        
        tl.to(words.current, {y:`-=10%`, duration: 0.5, ease:"power2.inOut"}, "+=1")
        tl.to($('p'), {opacity:'0', duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        tl.to($('p:nth-of-type(5)'), {color:'#C53427', opacity:1, duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        
        tl.to(words.current, {y:`-=10%`, duration: 0.5, ease:"power2.inOut"}, "+=1")
        tl.to($('p'), {opacity:'0', duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        tl.to($('p:nth-of-type(6)'), {color:'#C53427', opacity:1, duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        
        tl.to(words.current, {y:`-=10%`, duration: 0.5, ease:"power2.inOut"}, "+=1")
        tl.to($('p'), {opacity:'0', duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        tl.to($('p:nth-of-type(7)'), {color:'#C53427', opacity:1, duration: 0.5, ease:"power2.inOut"}, "-=0.5")

        tl.to(words.current, {y:`-=10%`, duration: 0.5, ease:"power2.inOut"}, "+=1")
        tl.to($('p'), {opacity:'0', duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        tl.to($('p:nth-of-type(8)'), {color:'#C53427', opacity:1, duration: 0.5, ease:"power2.inOut"}, "-=0.5")

        tl.to(words.current, {y:`-=10%`, duration: 0.5, ease:"power2.inOut"}, "+=1")
        tl.to($('p'), {opacity:'0', duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        tl.to($('p:nth-of-type(9)'), {color:'#C53427', opacity:1, duration: 0.5, ease:"power2.inOut"}, "-=0.5")

        tl.to(words.current, {y:`-=10%`, duration: 0.5, ease:"power2.inOut"}, "+=1")
        tl.to($('p'), {opacity:'0', duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        tl.to($('p:nth-of-type(10)'), {color:'#C53427', opacity:1, duration: 0.5, ease:"power2.inOut"}, "-=0.5")

        tl.to(words.current, {y:`-=10%`, duration: 0.5, ease:"power2.inOut"}, "+=1")
        tl.to($('p'), {opacity:'0', duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        tl.to($('p:nth-of-type(11)'), {color:'#C53427', opacity:1, duration: 0.5, ease:"power2.inOut"}, "-=0.5")
        

    
        return () =>  tl.kill();
    }, []); 


    return(
        <Wrapper>
            <img className="main-circle" srcSet={`${communityLogo} 2x`} alt="Trusted, Authentic, Humble, Versatile, Inclusive"/>
            <div className="words-wrapper" ref={words}>
                <p>Behavioral Health</p>
                <p>NASH & Liver Disease</p>
                <p>Chronic Kidney Disease</p>
                <p>Oncology</p>
                <p>Women's Health</p>
                <p>Behavioral Health</p>
                <p>NASH & Liver Disease</p>
                <p>Chronic Kidney Disease</p>
                <p>Oncology</p>
                <p>Women's Health</p>            
            </div>
        </Wrapper>
    )
}

export default WordCarousel