import React from "react";
import { Wrapper } from "./Map.styled"

//Assets
import mapVector from "../../../images/NOE/noe-map-2-Oct2023-Update.svg"
import logo from "../../../images/NOE/NOELogo@2x.png";

const Map = ({data})=> { 
    return (
        <Wrapper>
            <div className="title">
                <h2 dangerouslySetInnerHTML={{ __html: data.title  }} />
            </div>
            <div className="mapWrapper">
                <div className="mapImg bgm2">
                    <img src={mapVector} alt="" className="bigMap" />
                </div>
                <div className="mapLegend bgm">
                    <div className="container">
                        <img src={logo} alt="" className="logo" />
                        <div className="dots">
                            <div className="dot">
                                <div className="square ckd">&nbsp;</div><div className="dottitle">CKD</div>
                            </div>
                            <div className="dot">
                                <div className="square behavioral-heath">&nbsp;</div><div className="dottitle">BEHAVIORAL HEALTH</div>
                            </div>
                            <div className="dot">
                                <div className="square heart-health">&nbsp;</div><div className="dottitle">HEART HEALTH</div>
                            </div>
                            <div className="dot">
                                <div className="square nash">&nbsp;</div><div className="dottitle">NASH</div>
                            </div>
                            <div className="dot">
                                <div className="square oncology">&nbsp;</div><div className="dottitle">ONCOLOGY</div>
                            </div>
                            <div className="dot">
                                <div className="square population-health">&nbsp;</div><div className="dottitle">POPULATION HEALTH</div>
                            </div>
                            <div className="dot">
                                <div className="square womens-health">&nbsp;</div><div className="dottitle">WOMEN’S HEALTH</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Map;