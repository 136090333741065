import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`max-w-screen-xl py-2 lg:py-2 lg:py-8 px-8 xl:px-0 mx-auto`}

    .title {
        color: #333333;
        ${tw`text-left lg:text-center`}
    }

    .mapWrapper{
        ${tw`
            grid 
            grid-cols-1 lg:grid-cols-12 xl:grid-cols-4 
            gap-4 lg:gap-6`
        }
    }

    .mapWrapper > .bgm {
        background-color: rgba(235,234,234,0.46);
    }

    .mapWrapper > .bgm2 {
        background-color: rgba(235,234,234,0.16);
    }

    .mapWrapper > .mapImg {
        ${tw`
            py-6 px-3.5
            col-span-1 lg:col-span-8 xl:col-span-3 `
        }
    }

    .mapWrapper > .mapImg > img {
        ${tw`w-full mx-auto lg:mx-0`}
    }
    
    .mapWrapper .mapLegend {
        ${tw`
            lg:col-span-4 xl:col-span-1
            py-6 lg:py-0 px-0 lg:px-11 relative`
        }
    }


    .mapWrapper .mapLegend .container {
        ${tw`w-10/12 lg:w-full mx-auto lg:mx-0 relative lg:absolute flex flex-col flex-wrap justify-center lg:justify-start`}
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .mapWrapper .mapLegend .logo {
        max-width: 266px;
        ${tw`pb-10 px-0 md:px-8 lg:pb-10 mx-auto flex flex-col w-full`}
    }

    @media (max-width: 1024px) {
        .mapWrapper .mapLegend .container {
           position: initial;
           transform: none;
           flex-wrap: wrap;
        }
    }

    .mapWrapper .mapLegend  .dots  {
        ${tw`flex flex-col w-max mx-auto`}
    }



    .mapWrapper .mapLegend  .dots  .dot {
        ${tw`flex flex-row mb-2 lg:mb-4 xl:mb-7`}
    }

    .mapWrapper .mapLegend  .dots  .dot:last-of-type {
        ${tw`mb-0`}
    }

    .dottitle {
        ${tw`flex flex-col text-left w-max text-sm lg:text-lg font-bold`}
    }

    .square {
        ${tw`flex flex-col w-max mr-2.5 mt-0 lg:mt-1.5`}
        background: #000;
        width:15px;
        height:15px;
        border: 1px solid #CBABAB;
    }

    .square.nash {
        background: #167EAA;
    }

    .square.healthy {
        background: #C53427;
    }
    
    .square.oncology {
        background: #01B0F0;
    }

    .square.ckd {
        background: #01B04F;
    }

    .square.behavioral-health {
        background: #083B5D;
    }

    .square.women {
        background: #F36F34;
    }

    .square.heart-health{
        background: #C6310E;
    }

    .square.population-health{
        background: #910176;
    }

    .square.womens-health{
        background: #FF6E2D;
    }
`